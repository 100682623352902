import React from "react"
import { themeGet } from 'styled-system'
import Hero from "../components/Hero"
import Layout from "../layouts"
import yourSVG from '../images/card-logo.svg'
import { graphql  } from 'gatsby'

import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
	0% {
		transform: translate(0, 100px);
		opacity:0;
	}
	100% {
		transform: translate(0, 0);
		opacity:1;
	}
`	

const CardList = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto, auto);
  grid-column-gap: 5em;
  grid-row-gap: 8em;
  margin: 8em auto 0;
  position: relative;
  opacity: 0;
  animation: ${fadeIn} ease 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards; 

  @media (max-width: ${themeGet('breakpoints.2')}) { 
  	grid-template-columns: repeat(2, 1fr);
  	grid-template-rows: repeat(auto, auto);
  }
  @media (max-width: ${themeGet('breakpoints.0')}) { 
  	grid-template-columns: repeat(1, 1fr);
  	grid-template-rows: repeat(auto, auto);
  	grid-row-gap: 2em;
  }
`

const CardBar = styled.div`
	width: inherit;
	height: 1em;
	margin-bottom: 2em;
	background: rgba(175,156,130,0.60);
`

const CardHeader = styled.h1`
	font-size: ${themeGet('fontSizes.5')};
	font-family: "Supria", sans-serif;
	text-transform: uppercase;
`
const CardLogoHeader = styled.h1`
	font-size: ${themeGet('fontSizes.5')};
	font-family: "Supria", sans-serif;
`
const CardDescription = styled.div`
	font-size: ${themeGet('fontSizes.4')};
	margin-bottom: 1em;
`
const CardLink = styled.a`
	font-size: ${themeGet('fontSizes.1')};
	font-family: "GraphikMedium";
	color: ${themeGet('colors.text')};
	text-transform: uppercase;
	text-decoration: none;
`

class Ventures extends React.Component {
	renderGrid(){
		const {mdx} = this.props.data;
		
		return mdx.frontmatter.companies.map((item, i) => {
			if (item.logo === "yes"){
				return (
					<div key={i}>
						<CardBar />
					 	<CardLogoHeader>
					 		<img src={yourSVG} alt="log"/> &nbsp;&nbsp;  {item.name}
					 	</CardLogoHeader>
					 	<CardDescription>{item.description}</CardDescription>
					 	<CardLink href={'//' + item.url} target="_blank">{item.url} &#x2192;</CardLink>
					</div>
				)
			}
			return (
				<div key={i}>
					<CardBar />
				 	<CardHeader>
				 		{item.name}
				 	</CardHeader>
				 	<CardDescription>{item.description}</CardDescription>
				 	<CardLink href={'//' + item.url} target="_blank">{item.url} &#x2192;</CardLink>
				</div>
			)
	    })
	}
	render() {
		const {mdx} = this.props.data;
		return (
			<Layout>
		 		<Hero data={mdx.frontmatter} />
		 		<CardList >{this.renderGrid()}</CardList>
		  	</Layout>
		)
	}
}

export default Ventures

export const pageQuery = graphql`
  query VenturesQuery($id: String) {
    mdx(id: { eq: $id }) {
      	id
      	frontmatter {
       		title
       		description
       		companies {
       			name
       			url
       			logo
       			description
       		}
			templateKey
      	}
      	code {
        	body
      	}
    }
  }
`;
